import React from 'react';
interface DevBottomProps{
    className?:string;
    width?:number;
    height?:number;
}

const DevBottom: React.FC<DevBottomProps> = ({ className, width , height}) =>(
    <svg className={className} width={width} height={height} viewBox="0 0 179 267" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="mask0_935_2984"  maskUnits="userSpaceOnUse" x="0" y="0" width="179" height="267">
<rect width="179" height="267" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_935_2984)">
<path d="M-84.3322 82.3445C-77.7028 65.7767 -67.4775 50.7504 -54.3344 38.262C-41.1914 25.7736 -25.4309 16.1085 -8.09808 9.90771C9.23468 3.7069 27.7436 1.11208 46.2012 2.29534C64.6588 3.47858 82.6432 8.41286 98.9619 16.771C115.281 25.129 129.56 36.7199 140.855 50.7749C152.149 64.8299 160.199 81.0278 164.472 98.2944C168.744 115.561 169.141 133.502 165.637 150.927C162.132 168.352 154.806 184.863 144.144 199.365L109.317 175.801C116.497 166.035 121.431 154.916 123.791 143.181C126.151 131.447 125.883 119.365 123.006 107.737C120.129 96.1095 114.708 85.2013 107.102 75.7363C99.4961 66.2713 89.8796 58.4657 78.8901 52.8371C67.9007 47.2086 55.7895 43.8857 43.3596 43.0888C30.9298 42.292 18.4654 44.0394 6.793 48.2152C-4.87938 52.391 -15.493 58.8997 -24.3439 67.3098C-33.1948 75.7198 -40.0808 85.839 -44.5452 96.9961L-84.3322 82.3445Z" fill="#192C53" fill-opacity="0.8"/>
<path d="M88.1443 242.013L160.52 210.42L94.8903 166.006L88.1443 242.013Z" fill="#192C53" fill-opacity="0.8"/>
</g>
</svg>


);
export default DevBottom;