import React from "react";

interface DefectProps {
  className?: string;
  width?: number;
  fill?: string;
  height?: number;
}
const IconDefect: React.FC<DefectProps> = ({ className, width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    width={width}
    height={height}
    viewBox="0 0 40 40"
    fill="none"
  >
    <circle cx="20" cy="20" r="20" fill="#192C53" />
    <mask
      id="mask0_900_1820"
      maskUnits="userSpaceOnUse"
      x="8"
      y="8"
      width="24"
      height="24"
    >
      <rect x="8" y="8" width="24" height="24" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_900_1820)">
      <path
        d="M15 30C13.6167 30 12.4375 29.5125 11.4625 28.5375C10.4875 27.5625 10 26.3833 10 25C10 23.6167 10.4875 22.4375 11.4625 21.4625C12.4375 20.4875 13.6167 20 15 20C16.3833 20 17.5625 20.4875 18.5375 21.4625C19.5125 22.4375 20 23.6167 20 25C20 26.3833 19.5125 27.5625 18.5375 28.5375C17.5625 29.5125 16.3833 30 15 30ZM28.6 29L22.2 22.6C22 22.3833 21.7875 22.1625 21.5625 21.9375C21.3375 21.7125 21.1167 21.5 20.9 21.3C21.5333 20.9 22.0417 20.3667 22.425 19.7C22.8083 19.0333 23 18.3 23 17.5C23 16.25 22.5625 15.1875 21.6875 14.3125C20.8125 13.4375 19.75 13 18.5 13C17.25 13 16.1875 13.4375 15.3125 14.3125C14.4375 15.1875 14 16.25 14 17.5C14 17.6 14.0042 17.6958 14.0125 17.7875C14.0208 17.8792 14.0333 17.975 14.05 18.075C13.75 18.1083 13.4208 18.175 13.0625 18.275C12.7042 18.375 12.3833 18.4917 12.1 18.625C12.0667 18.4417 12.0417 18.2583 12.025 18.075C12.0083 17.8917 12 17.7 12 17.5C12 15.6833 12.6292 14.1458 13.8875 12.8875C15.1458 11.6292 16.6833 11 18.5 11C20.3167 11 21.8542 11.6292 23.1125 12.8875C24.3708 14.1458 25 15.6833 25 17.5C25 18.2167 24.8875 18.8958 24.6625 19.5375C24.4375 20.1792 24.125 20.7667 23.725 21.3L30 27.6L28.6 29ZM13.225 27.475L15 25.7L16.75 27.475L17.475 26.775L15.7 25L17.475 23.225L16.775 22.525L15 24.3L13.225 22.525L12.525 23.225L14.3 25L12.525 26.775L13.225 27.475Z"
        fill="white"
      />
    </g>
  </svg>
);
export default IconDefect;
