import React from "react";

interface LuzProps {
  className?: string;
  width?: number;
  fill?: string;
  height?: number;
}
const IconLuz: React.FC<LuzProps> = ({ className, width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    width={width}
    height={height}
    viewBox="0 0 40 40"
    fill="none"
  >
    <circle cx="20" cy="20" r="20" fill="#192C53" />
    <mask
      id="mask0_900_1784"
      maskUnits="userSpaceOnUse"
      x="8"
      y="8"
      width="24"
      height="24"
    >
      <rect x="8" y="8" width="24" height="24" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_900_1784)">
      <path
        d="M19 30V27H21V30H19ZM10 21V19H13V21H10ZM27 21V19H30V21H27ZM26 27.9L23.9 25.8L25.3 24.4L27.4 26.5L26 27.9ZM14 27.9L12.6 26.5L14.7 24.4L16.1 25.8L14 27.9ZM20 25C18.6167 25 17.4375 24.5125 16.4625 23.5375C15.4875 22.5625 15 21.3833 15 20C15 19.2 15.1792 18.4542 15.5375 17.7625C15.8958 17.0708 16.3833 16.4833 17 16V11H23V16C23.6167 16.4833 24.1042 17.0708 24.4625 17.7625C24.8208 18.4542 25 19.2 25 20C25 21.3833 24.5125 22.5625 23.5375 23.5375C22.5625 24.5125 21.3833 25 20 25ZM19 15.1C19.1667 15.0667 19.3333 15.0417 19.5 15.025C19.6667 15.0083 19.8333 15 20 15C20.1667 15 20.3333 15.0083 20.5 15.025C20.6667 15.0417 20.8333 15.0667 21 15.1V13H19V15.1ZM20 23C20.8333 23 21.5417 22.7083 22.125 22.125C22.7083 21.5417 23 20.8333 23 20C23 19.1667 22.7083 18.4583 22.125 17.875C21.5417 17.2917 20.8333 17 20 17C19.1667 17 18.4583 17.2917 17.875 17.875C17.2917 18.4583 17 19.1667 17 20C17 20.8333 17.2917 21.5417 17.875 22.125C18.4583 22.7083 19.1667 23 20 23Z"
        fill="white"
      />
    </g>
  </svg>
);
export default IconLuz;
