import React from 'react';

interface ValeProps{
    className?:string;
    width?:number;
    fill?:string;
    height?:number;
}
const IconVale: React.FC<ValeProps> = ({ className , width, height }) =>(
  <svg xmlns="http://www.w3.org/2000/svg" className={className} width={width} height={height} viewBox="0 0 48 48" fill="none">
  <circle cx="24" cy="24" r="23" stroke="#00DF5E" stroke-width="2"/>
  <mask id="mask0_658_7338"  maskUnits="userSpaceOnUse" x="12" y="12" width="24" height="24">
  <rect x="12" y="12" width="24" height="24" fill="#D9D9D9"/>
  </mask>
  <g mask="url(#mask0_658_7338)">
  <path d="M16 32C15.45 32 14.9792 31.8042 14.5875 31.4125C14.1958 31.0208 14 30.55 14 30V26.625C14 26.4417 14.0583 26.2833 14.175 26.15C14.2917 26.0167 14.4417 25.9333 14.625 25.9C15.025 25.7667 15.3542 25.525 15.6125 25.175C15.8708 24.825 16 24.4333 16 24C16 23.5667 15.8708 23.175 15.6125 22.825C15.3542 22.475 15.025 22.2333 14.625 22.1C14.4417 22.0667 14.2917 21.9833 14.175 21.85C14.0583 21.7167 14 21.5583 14 21.375V18C14 17.45 14.1958 16.9792 14.5875 16.5875C14.9792 16.1958 15.45 16 16 16H32C32.55 16 33.0208 16.1958 33.4125 16.5875C33.8042 16.9792 34 17.45 34 18V21.375C34 21.5583 33.9417 21.7167 33.825 21.85C33.7083 21.9833 33.5583 22.0667 33.375 22.1C32.975 22.2333 32.6458 22.475 32.3875 22.825C32.1292 23.175 32 23.5667 32 24C32 24.4333 32.1292 24.825 32.3875 25.175C32.6458 25.525 32.975 25.7667 33.375 25.9C33.5583 25.9333 33.7083 26.0167 33.825 26.15C33.9417 26.2833 34 26.4417 34 26.625V30C34 30.55 33.8042 31.0208 33.4125 31.4125C33.0208 31.8042 32.55 32 32 32H16ZM24 29C24.2833 29 24.5208 28.9042 24.7125 28.7125C24.9042 28.5208 25 28.2833 25 28C25 27.7167 24.9042 27.4792 24.7125 27.2875C24.5208 27.0958 24.2833 27 24 27C23.7167 27 23.4792 27.0958 23.2875 27.2875C23.0958 27.4792 23 27.7167 23 28C23 28.2833 23.0958 28.5208 23.2875 28.7125C23.4792 28.9042 23.7167 29 24 29ZM24 25C24.2833 25 24.5208 24.9042 24.7125 24.7125C24.9042 24.5208 25 24.2833 25 24C25 23.7167 24.9042 23.4792 24.7125 23.2875C24.5208 23.0958 24.2833 23 24 23C23.7167 23 23.4792 23.0958 23.2875 23.2875C23.0958 23.4792 23 23.7167 23 24C23 24.2833 23.0958 24.5208 23.2875 24.7125C23.4792 24.9042 23.7167 25 24 25ZM24 21C24.2833 21 24.5208 20.9042 24.7125 20.7125C24.9042 20.5208 25 20.2833 25 20C25 19.7167 24.9042 19.4792 24.7125 19.2875C24.5208 19.0958 24.2833 19 24 19C23.7167 19 23.4792 19.0958 23.2875 19.2875C23.0958 19.4792 23 19.7167 23 20C23 20.2833 23.0958 20.5208 23.2875 20.7125C23.4792 20.9042 23.7167 21 24 21Z" fill="#00DF5E"/>
  </g>
  </svg>

);
export default IconVale;