import React from 'react';
interface LogoFutProps{
    className?:string;
    width?:number;
    height?:number;
}

const LogoFut: React.FC<LogoFutProps> = ({ className, width , height}) =>(
    <svg className={className} width={width} height={height} viewBox="0 0 125 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.002 43.9392H12.1641L23.2221 32.8105H28.1982L30.4098 43.9392H25.7101L25.4337 42.1275H20.0429L22.9456 39.2807H24.8808C24.8808 39.1513 24.8808 38.8925 24.8808 38.6336C24.8808 38.3748 24.7425 38.116 24.7425 37.9866C24.7425 37.7278 24.6043 37.469 24.6043 37.2102C24.6043 36.9514 24.4661 36.6926 24.4661 36.4338L17.0019 43.9391L17.002 43.9392Z" fill="#192C53"/>
    <path d="M38.5651 36.4338L35.6623 43.8098H31.6538L35.9388 32.8105H42.2972L45.891 40.1865L48.7938 32.8105H52.8023L48.3791 43.9392H42.0207L38.5651 36.4338L38.5651 36.4338Z" fill="#192C53"/>
    <path d="M53.6314 43.8103H48.7935L59.8515 32.6816H64.8276L67.0392 43.8103H62.3395L62.0631 41.9986H56.6723L59.575 39.1518H61.5102C61.5102 38.8929 61.3719 38.6342 61.3719 38.3753C61.3719 38.1165 61.3719 38.1165 61.2337 37.8577C61.2337 37.5989 61.0955 37.3401 61.0955 37.0813C61.0955 36.8225 60.9572 36.5637 60.9572 36.3049L53.6313 43.8102L53.6314 43.8103Z" fill="#192C53"/>
    <path d="M73.3978 43.8103H69.251L72.4302 35.6579H67.3159L68.56 32.6816H82.7972L81.5531 35.7873H76.577L73.3978 43.8102V43.8103Z" fill="#192C53"/>
    <path d="M88.6022 32.6816L84.179 43.8103H80.0322L84.4554 32.6816H88.6022Z" fill="#192C53"/>
    <path d="M89.0172 35.9167C89.4318 34.8815 89.9847 34.1051 90.9523 33.4581C91.9199 32.9405 93.1639 32.6816 94.2697 32.6816H104.498L103.254 35.7873H93.4404C93.3022 35.7873 93.0257 35.7873 92.8875 35.9167C92.7493 35.9167 92.611 36.0461 92.611 36.1755L92.1964 37.0813L91.2288 39.54C91.2288 39.6694 91.0906 39.7988 91.0906 39.9282C91.0906 40.0576 90.9523 40.187 90.9523 40.3164C90.9523 40.4458 91.0906 40.7046 91.5052 40.7046H101.319L100.075 43.8102H89.8465C88.8789 43.9396 87.9113 43.6808 87.082 43.0338C86.6673 42.2574 86.6673 41.3516 87.082 40.7045L89.0172 35.9167V35.9167Z" fill="#192C53"/>
    <path d="M118.459 38.1165C118.597 38.893 118.459 39.6694 118.044 40.3164C117.63 41.3516 116.938 42.2574 116.109 43.0338C115.142 43.6809 114.036 43.9396 112.93 43.8103H104.498L103.531 43.5514L102.978 43.1633L102.701 42.7751V41.9986L103.116 40.9634H112.377C112.653 40.9634 112.93 40.9634 113.344 40.834C113.621 40.7046 113.897 40.5752 113.897 40.3164C114.036 40.0576 113.897 39.7988 113.621 39.7988C113.483 39.7988 113.206 39.6694 113.068 39.6694C112.93 39.6694 112.653 39.6694 112.377 39.6694C112.239 39.6694 112.1 39.6694 111.962 39.6694C111.824 39.6694 111.548 39.6694 111.271 39.6694H110.718C110.027 39.6694 109.474 39.6694 109.059 39.6694C108.645 39.6694 108.368 39.6694 108.23 39.6694C108.092 39.6694 107.954 39.6694 107.677 39.6694C107.539 39.6694 107.401 39.6694 107.263 39.6694C107.124 39.6694 107.124 39.6694 106.986 39.6694C106.848 39.6694 106.848 39.6694 106.71 39.6694H106.433C105.327 39.6694 104.636 39.2812 104.36 38.6342C104.222 38.3753 104.222 37.9871 104.222 37.7283C104.36 37.3402 104.36 36.8225 104.636 36.4343C105.051 35.3991 105.604 34.4933 106.433 33.7169C107.401 32.9404 108.506 32.6816 109.474 32.6816H118.182L118.873 32.9405L119.426 33.1993L119.703 33.7169L119.841 34.3639L119.426 35.5285H110.165C109.889 35.5285 109.612 35.5285 109.336 35.658C109.059 35.7874 108.921 35.9168 108.783 36.1756C108.645 36.4344 108.783 36.5638 108.921 36.6932C109.198 36.8226 109.474 36.8226 109.751 36.8226H110.442C110.58 36.8226 110.995 36.8226 111.409 36.8226H111.962C112.515 36.8226 113.068 36.8226 113.483 36.8226C113.897 36.8226 114.174 36.8226 114.45 36.8226C114.588 36.8226 114.727 36.8226 114.865 36.8226C115.003 36.8226 115.141 36.8226 115.28 36.8226H115.418H115.556C115.694 36.8226 115.833 36.8226 115.971 36.8226C116.109 36.8226 116.247 36.8226 116.385 36.8226C116.8 36.8226 117.353 36.952 117.768 37.2108C118.044 37.4696 118.321 37.7284 118.459 38.1166L118.459 38.1165Z" fill="#192C53"/>
    <path d="M4.285 43.9397H0L4.56142 32.6816H17.5546L16.3106 35.9167H7.60239L6.91126 37.3401L5.80546 40.3164L4.285 43.9397ZM7.74063 37.3402H14.7901L13.5461 40.3164H6.49659L7.74063 37.3402Z" fill="#192C53"/>
    <path d="M60.7052 0L53.5175 17.8576C53.3793 18.1164 53.5175 18.5046 53.794 18.634C54.2086 18.8928 54.7615 19.0222 55.3144 18.8928H67.8929C68.5841 18.8928 69.137 18.7634 69.6899 18.634C70.1045 18.5046 70.381 18.2458 70.5193 17.8576L77.5687 0H87.6592L80.0568 18.8928C79.6421 20.0574 79.0893 21.0926 78.2599 22.1278C77.4305 23.1631 76.6012 23.9395 75.4954 24.5865C74.9425 24.9747 74.3896 25.2335 73.9749 25.4923C73.5603 25.7511 73.1456 25.8805 72.7309 26.1393C72.3162 26.3982 70.934 26.7864 69.9664 26.9158C69.4135 27.0452 68.7224 27.1746 68.1695 27.1746C67.3401 27.1746 66.649 27.304 65.8196 27.304C64.9903 27.304 63.8845 27.304 62.5022 27.304C61.12 27.304 59.7378 27.304 58.0791 27.304C56.4204 27.304 54.8999 27.304 53.6559 27.304C52.4118 27.304 51.306 27.304 50.4767 27.304C49.0944 27.304 47.8504 27.1746 46.4681 26.9158C46.0535 26.9158 45.6388 26.7864 45.2241 26.657C44.9476 26.5276 44.533 26.3982 44.2565 26.2688C43.9801 26.1394 43.7036 25.8806 43.4272 25.6218C43.1507 25.363 42.8743 24.9748 42.5978 24.716C42.0449 23.9395 41.7684 23.1632 41.7684 22.2573C41.9067 21.2221 42.1831 20.0575 42.5978 19.0222L50.6149 8.59314e-05H60.7053L60.7052 0Z" fill="#192C53"/>
    <path d="M102.035 27.0451H91.8059L99.6848 7.24655H87.2445L90.2855 0H124.98L122.077 7.24655H110.052L102.035 27.0451Z" fill="#192C53"/>
    <path d="M15.782 27.0451H5.55334L16.4731 0H47.4356L44.3946 7.63475H23.6608L22.2785 11.1286L19.3758 18.3752L15.7819 27.0451H15.782ZM23.7991 11.1286H40.5244L37.6216 18.3752H20.8964L23.7991 11.1286Z" fill="#192C53"/>
    </svg>
    


);
export default LogoFut;