import React from "react";

interface NegadaProps {
  className?: string;
  width?: number;
  fill?: string;
  height?: number;
}
const IconNegada: React.FC<NegadaProps> = ({ className, width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    width={width}
    height={height}
    viewBox="0 0 64 64"
    fill="none"
  >
<mask id="mask0_922_1179"maskUnits="userSpaceOnUse" x="12" y="12" width="40" height="40">
<rect x="12" y="12" width="40" height="40" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_922_1179)">
<path d="M20.3333 25.3333H34.4167H33.6667H34.25H20.3333ZM21 22H43L41.5833 20.3333H22.4167L21 22ZM28.6667 33.25L32 31.5833L35.3333 33.25V25.3333H28.6667V33.25ZM36.25 47H20.3333C19.4167 47 18.6319 46.6736 17.9792 46.0208C17.3264 45.3681 17 44.5833 17 43.6667V22.875C17 22.4861 17.0625 22.1111 17.1875 21.75C17.3125 21.3889 17.5 21.0556 17.75 20.75L19.8333 18.2083C20.1389 17.8194 20.5208 17.5208 20.9792 17.3125C21.4375 17.1042 21.9167 17 22.4167 17H41.5833C42.0833 17 42.5625 17.1042 43.0208 17.3125C43.4792 17.5208 43.8611 17.8194 44.1667 18.2083L46.25 20.75C46.5 21.0556 46.6875 21.3889 46.8125 21.75C46.9375 22.1111 47 22.4861 47 22.875V31.0417C46.4722 30.8472 45.9306 30.6944 45.375 30.5833C44.8194 30.4722 44.25 30.4167 43.6667 30.4167V25.3333H38.6667V31.7083C37.6944 32.2639 36.8472 32.9514 36.125 33.7708C35.4028 34.5903 34.8333 35.5139 34.4167 36.5417L32 35.3333L25.3333 38.6667V25.3333H20.3333V43.6667H34.25C34.4722 44.3056 34.75 44.9028 35.0833 45.4583C35.4167 46.0139 35.8056 46.5278 36.25 47Z" fill="#CE0000"/>
<path d="M40.1667 46.1667L37.8334 43.8333L41.3334 40.3333L37.8334 36.8333L40.1667 34.5L43.6667 38L47.1667 34.5L49.5 36.8333L46.0417 40.3333L49.5 43.8333L47.1667 46.1667L43.6667 42.7083L40.1667 46.1667Z" fill="#CE0000"/>
</g>
<path fill-rule="evenodd" clip-rule="evenodd" d="M32 0C49.6736 0 64 14.3264 64 32C64 49.6736 49.6736 64 32 64C14.3264 64 0 49.6736 0 32C0 14.3264 14.3264 0 32 0ZM60.8 32C60.8 16.0944 47.9056 3.2 32 3.2C16.0944 3.2 3.2 16.0944 3.2 32C3.2 47.9056 16.0944 60.8 32 60.8C47.9056 60.8 60.8 47.9056 60.8 32Z" fill="#CE0000"/>
</svg>
);
export default IconNegada;
