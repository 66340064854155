import React from "react";

interface BackProps {
  className?: string;
  width?: number;
  fill?: string;
  height?: number;
}
const IconBack: React.FC<BackProps> = ({ className, width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
  >
<mask id="mask0_669_1575"  maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
<rect width="24" height="24" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_669_1575)">
<path d="M7.82499 13.0008L12.725 17.9008C12.925 18.1008 13.0208 18.3341 13.0125 18.6008C13.0042 18.8674 12.9 19.1008 12.7 19.3008C12.5 19.4841 12.2667 19.5799 12 19.5883C11.7333 19.5966 11.5 19.5008 11.3 19.3008L4.69999 12.7008C4.59999 12.6008 4.52915 12.4924 4.48749 12.3758C4.44582 12.2591 4.42499 12.1341 4.42499 12.0008C4.42499 11.8674 4.44582 11.7424 4.48749 11.6258C4.52915 11.5091 4.59999 11.4008 4.69999 11.3008L11.3 4.70078C11.4833 4.51745 11.7125 4.42578 11.9875 4.42578C12.2625 4.42578 12.5 4.51745 12.7 4.70078C12.9 4.90078 13 5.13828 13 5.41328C13 5.68828 12.9 5.92578 12.7 6.12578L7.82499 11.0008H19C19.2833 11.0008 19.5208 11.0966 19.7125 11.2883C19.9042 11.4799 20 11.7174 20 12.0008C20 12.2841 19.9042 12.5216 19.7125 12.7133C19.5208 12.9049 19.2833 13.0008 19 13.0008H7.82499Z" fill="#1C1B1F"/>
</g>
</svg>
);
export default IconBack;
