import React from 'react';
interface IconTimeoutProps{
    className?:string;
    width?:number;
    height?:number;
}

const IconTimeout: React.FC<IconTimeoutProps> = ({ className, width , height}) =>(
    <svg className={className} width={width} height={height} viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M32 0C49.6736 0 64 14.3264 64 32C64 49.6736 49.6736 64 32 64C14.3264 64 0 49.6736 0 32C0 14.3264 14.3264 0 32 0ZM60.8 32C60.8 16.0944 47.9056 3.2 32 3.2C16.0944 3.2 3.2 16.0944 3.2 32C3.2 47.9056 16.0944 60.8 32 60.8C47.9056 60.8 60.8 47.9056 60.8 32Z" fill="#CE0000"/>
        <path d="M31.3334 46.9987C29.25 46.9987 27.2987 46.6098 25.4792 45.832C23.6598 45.0543 22.0695 43.9848 20.7084 42.6237C19.3473 41.2626 18.2778 39.6723 17.5 37.8529C16.7223 36.0334 16.3334 34.082 16.3334 31.9987C16.3334 29.9154 16.7223 27.964 17.5 26.1445C18.2778 24.3251 19.3473 22.7348 20.7084 21.3737C22.0695 20.0126 23.6598 18.9431 25.4792 18.1654C27.2987 17.3876 29.25 16.9987 31.3334 16.9987C31.9167 16.9987 32.4792 17.0334 33.0209 17.1029C33.5625 17.1723 34.1112 17.2765 34.6667 17.4154V20.832C34.1112 20.6654 33.5625 20.5404 33.0209 20.457C32.4792 20.3737 31.9167 20.332 31.3334 20.332C28.0556 20.332 25.2917 21.457 23.0417 23.707C20.7917 25.957 19.6667 28.7209 19.6667 31.9987C19.6667 35.2765 20.7917 38.0404 23.0417 40.2904C25.2917 42.5404 28.0556 43.6654 31.3334 43.6654C34.6112 43.6654 37.375 42.5404 39.625 40.2904C41.875 38.0404 43 35.2765 43 31.9987C43 31.6931 42.9862 31.4154 42.9584 31.1654C42.9306 30.9154 42.8889 30.6376 42.8334 30.332H46.25C46.3056 30.6376 46.3334 30.9154 46.3334 31.1654V31.9987C46.3334 34.082 45.9445 36.0334 45.1667 37.8529C44.3889 39.6723 43.3195 41.2626 41.9584 42.6237C40.5973 43.9848 39.007 45.0543 37.1875 45.832C35.3681 46.6098 33.4167 46.9987 31.3334 46.9987ZM36 38.9987L29.6667 32.6654V23.6654H33V31.332L38.3334 36.6654L36 38.9987Z" fill="#CE0000"/>
        <path d="M38.7709 28.2487L36.4376 25.9154L39.9376 22.4154L36.4376 18.9154L38.7709 16.582L42.2709 20.082L45.7709 16.582L48.1043 18.9154L44.6459 22.4154L48.1043 25.9154L45.7709 28.2487L42.2709 24.7904L38.7709 28.2487Z" fill="#CE0000"/>
    </svg>
);
export default IconTimeout;