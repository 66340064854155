import React from "react";

interface EnvioPendenteProps {
  className?: string;
  width?: number;
  fill?: string;
  height?: number;
}
const IconEnvioPendente: React.FC<EnvioPendenteProps> = ({ className, width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    width={width}
    height={height}
    viewBox="0 0 64 64"
    fill="none"
  >


<path d="M32 30.3327L45.3334 21.9993H18.6667L32 30.3327ZM32 33.666L18.6667 25.3327V41.9993H27.25L30.5834 45.3327H18.6667C17.75 45.3327 16.9653 45.0063 16.3125 44.3535C15.6598 43.7007 15.3334 42.916 15.3334 41.9993V21.9993C15.3334 21.0827 15.6598 20.298 16.3125 19.6452C16.9653 18.9924 17.75 18.666 18.6667 18.666H45.3334C46.25 18.666 47.0348 18.9924 47.6875 19.6452C48.3403 20.298 48.6667 21.0827 48.6667 21.9993V29.2493L45.3334 32.5827V25.3327L32 33.666Z" fill="#192C53"/>
<mask id="mask0_1727_1113"  maskUnits="userSpaceOnUse" x="32" y="32" width="16" height="16">
<rect x="32" y="32" width="16" height="16" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_1727_1113)">
<path d="M42.1292 43.2027L42.1999 43.2735L42.2706 43.2027L43.204 42.2694L43.2747 42.1987L43.204 42.128L40.7666 39.6906V36.6654V36.5654H40.6666H39.3333H39.2333V36.6654V40.2654V40.3068L39.2625 40.3361L42.1292 43.2027ZM37.3603 46.2322C38.1845 46.5878 39.0647 46.7654 39.9999 46.7654C40.9352 46.7654 41.8154 46.5878 42.6395 46.2322C43.4618 45.8774 44.178 45.3954 44.7873 44.7861C45.3966 44.1768 45.8786 43.4605 46.2334 42.6383C46.589 41.8141 46.7666 40.9339 46.7666 39.9987C46.7666 39.0635 46.589 38.1832 46.2334 37.3591C45.8786 36.5368 45.3966 35.8206 44.7873 35.2113C44.178 34.602 43.4618 34.12 42.6395 33.7652C41.8154 33.4096 40.9352 33.232 39.9999 33.232C39.0647 33.232 38.1845 33.4096 37.3603 33.7652C36.5381 34.12 35.8218 34.602 35.2125 35.2113C34.6032 35.8206 34.1212 36.5368 33.7664 37.3591C33.4108 38.1832 33.2333 39.0635 33.2333 39.9987C33.2333 40.9339 33.4108 41.8141 33.7664 42.6383C34.1212 43.4605 34.6032 44.1768 35.2125 44.7861C35.8218 45.3954 36.5381 45.8774 37.3603 46.2322ZM43.7042 43.703C42.6842 44.723 41.4513 45.232 39.9999 45.232C38.5485 45.232 37.3156 44.723 36.2956 43.703C35.2756 42.683 34.7666 41.4501 34.7666 39.9987C34.7666 38.5473 35.2756 37.3144 36.2956 36.2944C37.3156 35.2744 38.5485 34.7654 39.9999 34.7654C41.4513 34.7654 42.6842 35.2744 43.7042 36.2944C44.7242 37.3144 45.2333 38.5473 45.2333 39.9987C45.2333 41.4501 44.7242 42.683 43.7042 43.703Z" fill="#192C53" stroke="#192C53" stroke-width="0.2"/>
</g>
<path fill-rule="evenodd" clip-rule="evenodd" d="M32 0C49.6736 0 64 14.3264 64 32C64 49.6736 49.6736 64 32 64C14.3264 64 0 49.6736 0 32C0 14.3264 14.3264 0 32 0ZM60.8 32C60.8 16.0944 47.9056 3.2 32 3.2C16.0944 3.2 3.2 16.0944 3.2 32C3.2 47.9056 16.0944 60.8 32 60.8C47.9056 60.8 60.8 47.9056 60.8 32Z" fill="#192C53"/>
</svg>


);
export default IconEnvioPendente;
