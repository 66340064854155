import React from 'react';

interface InfoValeProps{
    className?:string;
    width?:number;
    fill?:string;
    height?:number;
}
const IconInfoVale: React.FC<InfoValeProps> = ({ className , width, height }) =>(
  <svg xmlns="http://www.w3.org/2000/svg" className={className} width={width} height={height} viewBox="0 0 80 65" fill="none">
<path d="M50.8469 16.0742L55.6138 20.8742L28.6014 48.0742L23.8345 43.2742L50.8469 16.0742ZM7.94483 0.0742188H71.5034C75.9128 0.0742188 79.4483 3.63422 79.4483 8.07422V24.0742C77.3412 24.0742 75.3204 24.9171 73.8304 26.4174C72.3405 27.9177 71.5034 29.9525 71.5034 32.0742C71.5034 34.196 72.3405 36.2308 73.8304 37.7311C75.3204 39.2314 77.3412 40.0742 79.4483 40.0742V56.0742C79.4483 60.5142 75.9128 64.0742 71.5034 64.0742H7.94483C5.83773 64.0742 3.81693 63.2314 2.32699 61.7311C0.837042 60.2308 0 58.196 0 56.0742V40.0742C4.40938 40.0742 7.94483 36.5142 7.94483 32.0742C7.94483 29.9525 7.10779 27.9177 5.61784 26.4174C4.1279 24.9171 2.1071 24.0742 0 24.0742V8.07422C0 5.95249 0.837042 3.91766 2.32699 2.41736C3.81693 0.917073 5.83773 0.0742188 7.94483 0.0742188ZM7.94483 8.07422V18.2342C10.3588 19.6358 12.3636 21.6529 13.7575 24.0824C15.1514 26.512 15.8853 29.2684 15.8853 32.0742C15.8853 34.8801 15.1514 37.6365 13.7575 40.066C12.3636 42.4956 10.3588 44.5126 7.94483 45.9142V56.0742H71.5034V45.9142C69.0895 44.5126 67.0847 42.4956 65.6908 40.066C64.2969 37.6365 63.563 34.8801 63.563 32.0742C63.563 29.2684 64.2969 26.512 65.6908 24.0824C67.0847 21.6529 69.0895 19.6358 71.5034 18.2342V8.07422H7.94483ZM29.7931 16.0742C33.0902 16.0742 35.7517 18.7542 35.7517 22.0742C35.7517 25.3942 33.0902 28.0742 29.7931 28.0742C26.496 28.0742 23.8345 25.3942 23.8345 22.0742C23.8345 18.7542 26.496 16.0742 29.7931 16.0742ZM49.6552 36.0742C52.9523 36.0742 55.6138 38.7542 55.6138 42.0742C55.6138 45.3942 52.9523 48.0742 49.6552 48.0742C46.3581 48.0742 43.6966 45.3942 43.6966 42.0742C43.6966 38.7542 46.3581 36.0742 49.6552 36.0742Z" fill="#192C53"/>
</svg>

);
export default IconInfoVale;