import React from "react";

interface AnaliseProps {
  className?: string;
  width?: number;
  fill?: string;
  height?: number;
}
const IconAnalise: React.FC<AnaliseProps> = ({ className, width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    width={width}
    height={height}
    viewBox="0 0 64 64"
    fill="none"
  >
<mask id="mask0_912_1141" maskUnits="userSpaceOnUse" x="12" y="12" width="40" height="40">
<rect x="12" y="12" width="40" height="40" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_912_1141)">
<path d="M37.5 39.832L39.8334 37.4987L33.6667 31.332V23.6654H30.3334V32.6654L37.5 39.832ZM32 48.6654C29.6945 48.6654 27.5278 48.2279 25.5 47.3529C23.4723 46.4779 21.7084 45.2904 20.2084 43.7904C18.7084 42.2904 17.5209 40.5265 16.6459 38.4987C15.7709 36.4709 15.3334 34.3043 15.3334 31.9987C15.3334 29.6931 15.7709 27.5265 16.6459 25.4987C17.5209 23.4709 18.7084 21.707 20.2084 20.207C21.7084 18.707 23.4723 17.5195 25.5 16.6445C27.5278 15.7695 29.6945 15.332 32 15.332C34.3056 15.332 36.4723 15.7695 38.5 16.6445C40.5278 17.5195 42.2917 18.707 43.7917 20.207C45.2917 21.707 46.4792 23.4709 47.3542 25.4987C48.2292 27.5265 48.6667 29.6931 48.6667 31.9987C48.6667 34.3043 48.2292 36.4709 47.3542 38.4987C46.4792 40.5265 45.2917 42.2904 43.7917 43.7904C42.2917 45.2904 40.5278 46.4779 38.5 47.3529C36.4723 48.2279 34.3056 48.6654 32 48.6654ZM32 45.332C35.6945 45.332 38.8403 44.0334 41.4375 41.4362C44.0348 38.839 45.3334 35.6931 45.3334 31.9987C45.3334 28.3043 44.0348 25.1584 41.4375 22.5612C38.8403 19.964 35.6945 18.6654 32 18.6654C28.3056 18.6654 25.1598 19.964 22.5625 22.5612C19.9653 25.1584 18.6667 28.3043 18.6667 31.9987C18.6667 35.6931 19.9653 38.839 22.5625 41.4362C25.1598 44.0334 28.3056 45.332 32 45.332Z" fill="#E3C10B"/>
</g>
<path fill-rule="evenodd" clip-rule="evenodd" d="M32 0C49.6736 0 64 14.3264 64 32C64 49.6736 49.6736 64 32 64C14.3264 64 0 49.6736 0 32C0 14.3264 14.3264 0 32 0ZM60.8 32C60.8 16.0944 47.9056 3.2 32 3.2C16.0944 3.2 3.2 16.0944 3.2 32C3.2 47.9056 16.0944 60.8 32 60.8C47.9056 60.8 60.8 47.9056 60.8 32Z" fill="#E3C10B"/>
</svg>
);
export default IconAnalise;
