import React from 'react';
interface DevTopProps{
    className?:string;
    width?:number;
    height?:number;
}

const DevTop: React.FC<DevTopProps> = ({ className, width , height}) =>(
    <svg className={className} width={width} height={height} viewBox="0 0 229 143" fill="none" xmlns="http://www.w3.org/2000/svg">

<mask id="mask0_935_3007" maskUnits="userSpaceOnUse" x="0" y="0" width="229" height="143">
<rect width="229" height="143" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_935_3007)">
<path d="M224.299 -101.281C237.929 -89.763 248.962 -75.319 256.665 -58.907C264.369 -42.4949 268.568 -24.49 268.983 -6.0861C269.398 12.3178 266.021 30.7 259.074 47.8414C252.128 64.9828 241.771 80.4918 228.691 93.3398C215.611 106.188 200.107 116.081 183.206 122.364C166.306 128.646 148.395 131.175 130.662 129.781C112.93 128.388 95.78 123.104 80.3507 114.281C64.9215 105.457 51.5652 93.2957 41.1676 78.6026L74.5166 52.9894C81.5186 62.8841 90.5131 71.074 100.904 77.0159C111.294 82.9579 122.843 86.5161 134.785 87.4545C146.727 88.393 158.788 86.6903 170.169 82.4594C181.551 78.2285 191.992 71.566 200.8 62.9138C209.608 54.2617 216.583 43.8175 221.261 32.274C225.939 20.7304 228.213 8.35137 227.934 -4.0423C227.654 -16.436 224.827 -28.561 219.639 -39.6133C214.451 -50.6657 207.021 -60.3927 197.843 -68.1493L224.299 -101.281Z" fill="#192C53" fill-opacity="0.7"/>
<path d="M18.3659 12.0069L25.5099 90.6536L88.362 42.3898L18.3659 12.0069Z" fill="#192C53" fill-opacity="0.7"/>
</g>
</svg>

);
export default DevTop;